.recall-evaluator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.text-area {
  width: 100%;
}

.recall-eval-results {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.evaluate-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.recall-eval-params {
  width: 300px;
  margin-top: 20px;
}
