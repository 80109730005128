.search-results {
  transition: all 0.4s ease-in-out;
  padding: 0 20px; /* Ensures equal padding on both sides */
}

.result-display {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.result-display.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-display .error {
  grid-column: span 4;
  color: red;
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .result-display {
    grid-template-columns: 1fr;
  }

  .result-display .error {
    grid-column: span 1;
  }
}

.notification-image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.result-display-tiled {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}
