.chat-messages {
  height: 500px;
  overflow-y: scroll;
  padding: 10px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}

.message {
  border-radius: 10px;
  padding: 10px;
  width: 65%;
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.user-message {
  background-color: #f1f1f1;
  margin-left: auto;
  margin-right: 0;
  color: black;
}

.agent-message {
  background-color: #007bff;
  color: white;
  margin: 10px 0;
  margin-left: 0;
  margin-right: auto;
}

.message-contents {
  display: flex;
  gap: 10px;
  align-items: center;
}

.message-contents .ant-avatar {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.message-input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  margin-top: 10px;
  display: flex;
}

.message-input button {
  height: 100px;
}

.product-display {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  overflow-x: scroll;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-image {
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  margin: 5px;
  color: black;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-image div {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.chat-controls {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 5px;
}
