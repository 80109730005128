.text-product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.favouriting-button {
  position: relative;
  top: 35px;
  left: 130px;
  z-index: 1;
}

.text-product-card:hover {
  transform: scale(1.05);
}

.text-card {
  width: 300px;
  height: 400px;
  border-radius: 10px;
  border-color: #a2a2a2;
  border-width: 2px;
  border-style: solid;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-presentation {
  margin: 20px;
}

.text-product-title {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-product-desc {
  font-size: 14px;
  color: #666;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-description-w-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-description-clamped {
  -webkit-line-clamp: 7 !important;
}

.text-description-w-display img {
  max-height: 100px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.text-title {
  font-size: 16px;
  height: 2.4rem;
  color: #000;
}

.text-price {
  color: #333;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 0 12px 12px;
}

.meta .actions {
  flex-shrink: 0;
}
