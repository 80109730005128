.favourite-tags {
  max-width: 800px;
  transition: all 0.4s ease-in-out;
  margin-left: auto;
  margin-right: auto;
}

.invisible-favourites {
  max-height: 0px;
  opacity: 0;
}

.visible-favourites {
  max-height: 1000px;
  opacity: 1;
}

.fav-heading {
  font-size: 20px;
  padding-bottom: 10px;
}
