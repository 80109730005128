.collections-bar {
  width: 100%;
  padding: 16px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  overflow-x: scroll;
}

.collections-container {
  display: flex;
  gap: 12px;
  min-width: min-content;
  max-width: 100%;
  padding: 0 24px;
  /* scrollbar-width: none; 
  -ms-overflow-style: none; */
}

.collections-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.collection-button {
  white-space: nowrap;
  border-radius: 20px;
  padding: 6px 20px;
  height: 36px;
  font-size: 14px;
  transition: all 0.3s ease;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  color: #595959;
  font-weight: 500;
}

.collection-button:hover {
  color: #020659;
  border-color: #020659;
  background: #ffffff;
}

.collection-button.active {
  background-color: #020659;
  color: white;
  border-color: #020659;
}

.collection-button.active:hover {
  background-color: #030a7a;
  border-color: #030a7a;
}

@media (max-width: 768px) {
  .collections-bar {
    padding: 12px 0;
  }

  .collections-container {
    padding: 0 16px;
    gap: 8px;
  }

  .collection-button {
    padding: 4px 16px;
    height: 32px;
    font-size: 13px;
  }
}
