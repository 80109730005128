.video-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: 450px;
  box-sizing: border-box;
  background-color: #fff;
  /* border: 2px solid #d4d4d4; */
  /* border-radius: 8px; */
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.video-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.favouriting-button,
.atc-button {
  position: absolute;
  top: 10px;
  z-index: 10;
}

.favouriting-button {
  left: 10px;
}

.atc-button {
  right: 10px;
}

.video-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}

.video-meta {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.video-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-price {
  font-size: 16px;
  color: #1890ff;
}

.video-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 12px 12px;
}

.actions button {
  width: 45%;
}

.video-card video {
  /* border-radius: 8px; */
  object-fit: cover;
  width: 100%;
  height: 200px;
}
