.search-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: 1200px;
  width: 90%;
  min-height: 60px;
  margin: 20px auto;
  border: 3px solid #9d9c9c;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  gap: 10px;
}

.search-bar-input {
  border: 3px solid #ccc;
  min-height: 20px;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s ease;
}

.search-bar-input:focus {
  border: 3px solid #020659;
}

.search-bar-input:last-child {
  margin-bottom: 0;
}

.search-button {
  min-width: 80px;
  /* border-radius: 5px; */
  background-color: #020659;
  border: none;
  cursor: pointer;
  min-height: 50px;
  transition: all 0.3s ease;
  color: #fff;
}

.search-button:hover {
  background-color: #454a9f;
}

@media (min-width: 800px) {
  .search-bar {
    flex-direction: row;
  }

  .search-bar-input {
    flex-grow: 1;
  }

  .search-button {
    flex-grow: 0;
  }
}

@media (max-width: 800px) {
  .search-type-checkbox {
    display: none;
  }
}
