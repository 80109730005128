.loading-extraction {
  justify-content: center;
  display: flex;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  align-items: start;
}

.extracted-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.image-container {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.extracted-item-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.extracted-item-name {
  font-size: 1em;
  text-align: center;
  margin-top: 10px;
  padding: 0 5px;
  height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  align-items: start;
}

.extracted-text-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}
.text-container {
  width: 100%;
  border-radius: 5px;
}

.extracted-item-description {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.extracted-item-name {
  font-size: 1em;
  text-align: center;
  margin-top: 10px;
  padding: 0 5px;
  height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .extracted-url-item {
  margin-top: 5px;
  margin-bottom: 5px;
} */
