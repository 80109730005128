.loading-recommendations {
  justify-content: center;
  display: flex;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  align-items: start;
}

.recommended-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.image-container {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.recommended-item-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.recommended-item-name {
  font-size: 1em;
  text-align: center;
  margin-top: 10px;
  padding: 0 5px;
  height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  align-items: start;
}

.recommended-text-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}
.text-container {
  width: 100%;
  border-radius: 5px;
}

.recommended-item-description {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recommended-item-name {
  font-size: 1em;
  text-align: center;
  margin-top: 10px;
  padding: 0 5px;
  height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.recommended-description-w-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recommended-description-clamped {
  -webkit-line-clamp: 4 !important;
}

.recommended-description-w-display img {
  max-height: 100px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  align-items: start;
}

.recommended-item-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.video-container {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
