.image-tile {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.image-tile img {
  height: 100%;
  width: auto; /* Maintain aspect ratio with variable width */
  object-fit: cover; /* Fill container without distortion */
}

.image-tile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: opacity 0.3s ease;
  will-change: opacity;
}

.image-tile:hover .image-tile-overlay {
  opacity: 1;
}

.image-tile-meta {
  text-align: center;
}

.image-tile-actions {
  display: flex;
  gap: 10px;
}
