.atc-items {
  max-width: 800px;
  transition: all 0.4s ease-in-out;
  margin-left: auto;
  margin-right: auto;
}

.cart-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
