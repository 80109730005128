.recommend-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.diversity-slider-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.diversity-slider {
  min-width: 300px;
}
