.search-settings {
  display: flex;
  justify-content: center;
}

.results-container {
  display: table;
  width: 100%;
}

.results-container .facets-container {
  display: table-cell;
  width: 300px;
  vertical-align: top;
}

.results-container .results-display-container {
  display: table-cell;
  vertical-align: top;
}

/* Media query for mobile */
@media (max-width: 768px) {
  .search-settings {
    flex-direction: column;
    align-items: center;
  }
}
