.modifier-constructor {
  position: relative;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.add-modifier {
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
}

.modifiers {
  margin-bottom: 20px;
}

.modifier-entry {
  margin-bottom: 10px;
}
