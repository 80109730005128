.filter-constructor {
  position: relative;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.add-filter {
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
}

.filters {
  margin-bottom: 20px;
}

.filter-entry {
  margin-bottom: 10px;
}
