.live-recommendations-container {
  border: 2px solid #a2a2a2;
  border-radius: 10px;
  margin: 20px;
}

.live-recommendations {
  margin: 10px;
}

.live-recommendations img {
  border-radius: 5px;
  object-fit: cover;
}

/* .live-recommendation-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    height: 100px;
} */

/* .live-recommendation-carousel {
    .react-multi-carousel-list {
        padding: 10px;
    }
} */
