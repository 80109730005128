@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.App {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
}

.ant-btn {
  box-shadow: none !important;
}
