.ant-layout-header {
  background-color: #ffffff !important;
}

.marqo-logo-img {
  max-width: 200px;
}

.advanced-model-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.advanced-modals {
  display: flex;
  gap: 10px;
}

.demo-selector {
  max-width: 300px;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .marqo-logo-img {
    width: 150px;
    height: auto;
  }
}

.mobile-burger-btn {
  display: none;
}

.desktop-components {
  display: flex;
}

/* Show burger button on mobile */
@media (max-width: 768px) {
  .mobile-burger-btn {
    margin-left: auto;
    display: block;
  }
  .desktop-components {
    display: none;
  }
}
